import "./components/carousel-slider/CarouselSlider";
import "./components/typewriter/TypeWriter";
import "./components/image-gallery/ImageGallery";
import "./components/image-grid/ImageGrid";
import "./components/video-player/VideoPlayer";
import "./components/pdf-flip-book/PdfFlipBook";
import "./components/pdf-viewer/PdfFrame";
import "./components/pdf-viewer/PdfPage";
import "./components/pdf-viewer/PdfViewer";
import "./components/language-chooser/LanguageChooser";
import "./animations/reveal/reveal";
import "./components/menu-bar/MenuBar";
import "./components/menu-item/MenuItem";
import "./components/image-carousel/ImageCarousel";
import "./components/horizontal-list/HorizontalList";
import "./components/full-screen-image-gallery/FullScreenImageGallery";
import { installCss } from "./core/installCss";

installCss("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free", "@6.6.0/css/all.min.css");

